import React, {useContext, useReducer, useEffect} from "react";
import Button from "@mui/material/Button";
import kindergymlogo from "../imgs/kindergymlogo.png";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import ListItemButton from "@mui/material/ListItemButton";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import {useNavigate, Link} from "react-router-dom";
import {AuthContext} from "../contexts/authcontexts";
import {ivory} from "../styling/colors";

const drawerStyle = {
  backgroundColor: ivory,
  height: "50vh",
  borderBottomRightRadius: "1rem",
  paddingRight: "0.5rem",
  display: "flex",
  justifyContent: "center"
}

const initialState = {isMobile: false, openDrawer: false}

export default function Navbar(props) {

const {viewNavbar} = useContext(AuthContext);
let navigate = useNavigate();
let mediaQuery = window.matchMedia('(max-width: 767px)');

function reducer(state, action){
  switch(action.type) {
    case "isMobile": return {...state, isMobile: action.payload}
    case "openDrawer": return {... state, openDrawer: action.payload}
    default: throw new Error("something went wrong in the reducer");
  }}
const [state, dispatch] = useReducer(reducer, initialState)

function handleClick(direction){
  navigate(direction);
  dispatch({type: "openDrawer", payload: false});
}

const navbarMenu = [
  {title: "HOME", fn: () => handleClick("/")},
  {title: "REGISTRATION", fn: () => window.open("https://docs.google.com/spreadsheets/d/13J9KBjdFYfn2qTIdB7jBaRQYtKc3AHXojyAytdJjpQI/edit?usp=share_link", "_blank").focus()},
  {title: "ATTENDANCE", fn: () => handleClick("/attendance")},
  // {title: "TEST", fn: () => handleClick("/test")},
]

useEffect(() => {if(mediaQuery.matches){
  dispatch({type: "isMobile", payload: true})}else{
  dispatch({type: "isMobile", payload: false})}}, [])

  return (
    <nav>
      <>{viewNavbar?
        <>{state.isMobile?
          <div>
            <Button onClick={() => dispatch({type: "openDrawer", payload: !state.openDrawer})}>
              <MenuRoundedIcon id="mobileMenuIcon"/>
            </Button>
            <SwipeableDrawer
              open={state.openDrawer}
              PaperProps={{style: drawerStyle}}
              onClose={() => dispatch({type: "openDrawer", payload: false})}
              onOpen={() => dispatch({type: "openDrawer", payload: true})}>
                <div id="drawerMenu">
                  {navbarMenu.map(menu =>
                    <ListItemButton key={menu.title} onClick={menu.fn}>
                      <h2>{menu.title}</h2>
                    </ListItemButton>)}
                </div>
            </SwipeableDrawer>
          </div> :
          <div>{navbarMenu.map(menu => 
            <Button key={menu.title} variant="text" onClick={menu.fn}>
              <h2>{menu.title}</h2>
            </Button>)}
          </div>}
        </> : null}
      </> 
      <Link to={"/"}><img src={kindergymlogo} alt="kindergym logo"/></Link>
    </nav>
  )
}
