import React, {useContext, useEffect, useReducer} from "react";
import dayjs from "dayjs";
import CircularProgress from "@mui/material/CircularProgress";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import {UserDataContext} from "../contexts/userdatacontext";
import {firestoreDB} from "../firebase";
import {getDoc, doc} from "firebase/firestore";
import {centerThings} from "../styling/cssclasses";

const initialState = {
  collectionData: {},
  attendanceRecords: {},
  sortedAttendanceData: [],
}

const flexColumn = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
}

const flexRow = {
  display: "flex",
  flexDirection: "row",
  gap: "0.5rem"
}

export default function AttendanceTest(props) {

const {userData, rfidRecords} = useContext(UserDataContext);

function reducer(state,action){
  switch(action.type) {
    case "collectionData": return {...state, collectionData: action.payload}
    case "attendanceRecords": return {...state, attendanceRecords: action.payload}
    case "sortedAttendanceData": return {...state, sortedAttendanceData: action.payload}
    default: throw new Error("Something went wrong")}}

const [state, dispatch] = useReducer(reducer, initialState)

useEffect(() => {
  async function getAttendanceRecords(){
    const attendanceSnapshot = await getDoc(doc(firestoreDB, userData.attendance, "123123"));
    if(attendanceSnapshot.exists()){dispatch({type: "attendanceRecords", payload: attendanceSnapshot.data()})}}
    getAttendanceRecords()
},[userData])

useEffect(() => {
  let arr = [];
  if(state.attendanceRecords){
    Object.keys(state.attendanceRecords).map(item =>
      {if(state.attendanceRecords[item].hasOwnProperty("seconds")){
        arr.push(dayjs.unix(state.attendanceRecords[item].seconds).format("ddd, DD-MM-YYYY HH:mm"));   
      }})}
      dispatch({type: "sortedAttendanceData", payload: arr.sort()})
    },[state.attendanceRecords]);

  return (
    <div className={centerThings}>
      <InputLabel>RFID</InputLabel> 
      <Select label="RFID" labelId="select-rfid">
        {rfidRecords? 
          rfidRecords.map(rfid => 
            <MenuItem value={rfid}>{rfid}</MenuItem>) : 
            <MenuItem value={"none"}>None</MenuItem>}
      </Select>
      <div id="attendanceRecords" className={props.box}>
        <div style={flexColumn}>
          <h3>{state.attendanceRecords? state.attendanceRecords.name : ""}</h3>
          <h3>{state.attendanceRecords? state.attendanceRecords.studentid : ""}</h3>
          <div style={flexRow}>
            <h3>{(state.attendanceRecords && state.attendanceRecords.class2)? "Classes:" : "Class:"}</h3>
            <h3>{state.attendanceRecords && state.attendanceRecords.class1? 
                `${state.attendanceRecords.class1.charAt(0).toUpperCase()}${state.attendanceRecords.class1.slice(1)}` 
                : <CircularProgress/>}</h3>
            <h3>{(state.attendanceRecords && state.attendanceRecords.class2)? 
                `${state.attendanceRecords.class2.charAt(0).toUpperCase()}${state.attendanceRecords.class2.slice(1)}` 
                : ""}</h3>
          </div>
        </div>
        &nbsp;  
        {state.sortedAttendanceData? 
         state.sortedAttendanceData.map(record => <li key={record}>{record}</li>) : <CircularProgress/>}
      </div>
    </div>
  )
}
