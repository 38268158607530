import React, {useContext} from "react";
import Button from "@mui/material/Button";
import {AuthContext} from "../contexts/authcontexts";

export default function SignOut(props) {

  const {handleSignOut} = useContext(AuthContext);

  return (
    <Button variant="contained" onClick={handleSignOut}>
      <h2>SIGN OUT</h2>
    </Button>
  )
}