import React, {useContext} from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {AuthContext} from "../contexts/authcontexts";
import {arrangeVertically} from "../styling/cssclasses";

export default function SignIn(props) {

const {usernameRef, passwordRef, handleSignIn} = useContext(AuthContext); 

return (
  <div className={arrangeVertically}>
    <TextField 
      id="username"
      error 
      variant="outlined"
      label="Silakan isi username"
      inputRef={usernameRef}/>
    <TextField 
      id="password"
      error 
      variant="outlined" 
      label="password"
      type="password"
      inputRef={passwordRef}/>
    <Button variant="contained" onClick={handleSignIn}><h3>SIGN IN</h3></Button>
  </div> 
)}
