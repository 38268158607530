import React from "react";
import Button from "@mui/material/Button";
import {arrangeVertically, centerThings} from "../styling/cssclasses";

export default function Attendance(props) {
 
  const attendancelinks = [{
    edit: "https://form.jotform.com/222078486133457",
    view: "https://docs.google.com/spreadsheets/d/1VnQtELNse36I7xCYgR5CHUqfhI67tBm_ktZcaK8C6L4/edit?usp=share_link" 
  }];

  return (
    <div className={centerThings}>
      <div className={props.box} style={{height: "40vh"}}>
        {attendancelinks.map(attendance => (
          <div key={attendance.edit} className={arrangeVertically} style={{height: "100%"}}>
            <Button 
              variant="contained" 
              onClick={() => window.open(attendance.edit, "_blank")}>
                CREATE ATTENDANCE REPORT 
            </Button>
            <Button 
              variant="contained" 
              onClick={() => window.open(attendance.view, "_blank")}>
                VIEW ATTENDANCE REPORT 
            </Button>
          </div>
        ))}
      </div>
    </div>
  )
}