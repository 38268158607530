import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import Layout from "./layout/layout";
import AuthContextProvider from "./contexts/authcontexts";
import UserDataProvider from "./contexts/userdatacontext";
import {BrowserRouter} from "react-router-dom";
import "./index.css";
import "@fontsource/dosis/700.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthContextProvider>
        <UserDataProvider>
          <Layout>
            <App />
          </Layout>
        </UserDataProvider>
      </AuthContextProvider>
    </BrowserRouter>
  </React.StrictMode>
);

