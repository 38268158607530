import React, {useEffect, useContext, createContext, useReducer} from "react";
import {AuthContext} from "./authcontexts";
import {firestoreDB} from "../firebase";
import {doc, collection, getDoc, getDocs} from "firebase/firestore";

export const UserDataContext = createContext(); 
const initialState = {}

const UserDataContextProvider = (props) => {
const {isOnline} = useContext(AuthContext);

function reducer(state, action){
  switch(action.type) {
    case "userData": return {...state, userData: action.payload}
    case "rfidRecords": return {...state, rfidRecords: action. payload}
    default: throw new Error("something went wrong")}}

const [state, dispatch] = useReducer(reducer, initialState);

useEffect(() => {
  async function tarikData(){
    if(isOnline){
      const documentSnapshot = await getDoc(doc(firestoreDB, "adminsteachers", isOnline))
      if(documentSnapshot.exists()){dispatch({type: "userData", payload: documentSnapshot.data()})
      }else{console.log("No user data")}
    }else{console.log("Nobody is online")}}
  tarikData();
}, [isOnline])

useEffect(() => {
  let arr = []
  async function getTheWholeCollection(){
    const collectionSnapshot  = await getDocs(collection(firestoreDB, state.userData.studentsdb))
    collectionSnapshot.forEach(doc => {
      arr.push(doc.data().rfid);
    })}
  getTheWholeCollection();
  if(arr.length){dispatch({type: "rfidRecords", payload: arr})};
},[state.userData])

  return( 
    <UserDataContext.Provider value={{userData: state.userData, rfidRecords: state.rfidRecords}}>
      {props.children}
    </UserDataContext.Provider>
  );
}

export default UserDataContextProvider;
