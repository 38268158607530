import React, {useContext} from "react";
import SignIn from "../authentication/signin";
import SignOut from "../authentication/signout";
import ImageViewer from "./imageviewer";
import UpdatePassword from "../authentication/updatepassword";
import {UserDataContext} from "../contexts/userdatacontext";
import {AuthContext} from "../contexts/authcontexts";
import {arrangeVertically, pageBasicCss} from "../styling/cssclasses";

export default function Dashboard(props) {

const {userData} = useContext(UserDataContext);
const {isOnline} = useContext(AuthContext);

  return (
    <div className={pageBasicCss}>
      <div id="break"/> 
      <div className={props.box}>
        <div className={arrangeVertically}>
          <h1>KINDERGYM INDONESIA</h1>
          <h3>ADMIN ACCESS</h3>
        </div>
        <div id="space"/>
        {isOnline && userData?
          <div className={arrangeVertically}>
            <div id="dashboard">
              <ImageViewer/>
              <p>{userData.name}</p>
              <p>{userData.role}</p>
            </div>
            <div className={arrangeVertically}>
              <UpdatePassword/>
              <SignOut/>
            </div> 
          </div>  
          : <div className={props.marginAuto}><SignIn/></div>}
      </div>
    </div>
  )
}
