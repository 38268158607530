import styles from "./App.module.css";
import Dashboard from "./dashboard/dashboard";
import ClaimsCheck from "./authentication/claimscheck";
import Attendance from "./attendance/attendance";
import AttendanceTest from "./attendance/attendancetest";
import {Routes, Route} from "react-router-dom";

function App() {
  
const cssProps = {
  box: styles.box,
  centerHorizontally: styles.centerHorizontally,	
  centerVertically: styles.centerVertically,
  flexHorizontally: styles.flexHorizontally,
  flexVertically: styles.flexVertically,
  gap: styles.gap,
  marginAuto: styles.marginAuto,
  minHeightMaxWidth: styles.minHeightMaxWidth,
}

  return (
    <div>
      <Routes>
        <Route path="/" element={<Dashboard {...cssProps}/>}/>
        <Route path="/claimscheck" element={<ClaimsCheck {...cssProps}/>}/>
        <Route path="/attendance" element={<Attendance {...cssProps}/>}/>
        <Route path="/test" element={<AttendanceTest {...cssProps}/>}/>
      </Routes>
    </div>
  );
}

export default App;
