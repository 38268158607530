import {initializeApp} from "firebase/app";
import {getFirestore} from "firebase/firestore";
import {getAuth} from "firebase/auth";
import {getStorage} from "firebase/storage";
import {getFunctions} from "firebase/functions";

const app = initializeApp({
  apiKey: "AIzaSyAssERYlTgoE1e4kbvszU5-Cni1ikYhaZk",
  authDomain: "kindergymwebapp.firebaseapp.com",
  projectId: "kindergymwebapp",
  storageBucket: "kindergymwebapp.appspot.com",
  messagingSenderId: "101535020556",
  appId: "1:101535020556:web:bcc7b4ccac97e8a958a029"
})

const firestoreDB = getFirestore(app);
const firebaseAuth = getAuth(app);
const firebaseStorage = getStorage(app);
const firebaseFunction = getFunctions(app, "asia-southeast2");

export {firestoreDB, firebaseAuth, firebaseStorage, firebaseFunction}

